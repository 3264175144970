const SDK = {
  BASE_URL: "https://minimis.cloud",
  SOCKET_URL: "https://ios.minimis.cloud",
  STRIPE_KEY:
    "pk_live_51Mq48pCHhPy4gmdpHje6ECXZRh9uYTmYFVm6sT3spEPtpwDWDBxDQRLe2dny342Mi3zRms5yHvOvhl9lbYrNb8Xu00np6bOu70",
  FB_PIXEL_ID: "672056548014780",
  TIK_TOK_PIXEL_ID: "CQOB3TBC77U11VCBB04G",
  FB_CURRNECY: "USD",
  FB_PRICE: "29",
  APPLE_PAY_PRICE: 2900,
  APPLE_PAY_CURRENCY: "usd",
};

export default SDK;
